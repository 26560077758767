<template>
    <div>
      <div class="main">
        <div style="
  margin-top: 2vw;
  cursor: pointer;
  font-weight: bold;
  font-size: 1vw;
" @click="$router.push({path:'/project'})"><&nbsp;&nbsp;返回主页</div>
        <div>
          <div class="filter">
            <div class="filter-left">
              <p style="font-weight: 600">学费(rmb)</p>
              <div class="block">
                <input :value="value[0]*5"></input>
                <input :value="value[1]*5"></input>
                <el-slider
                    v-model="value"
                    :max="100000"
                    @change="getFee(value)"
                    range>
                </el-slider>
              </div>
              <div class="eduction">
                <p style="font-weight: 600;margin-top: 4vh">教育程度</p>
                <select v-model="levelValue">
                  <option value="请选择" selected>请选择</option>
                  <option v-for="item of level" :key="item.id" :value="item.name">{{ item.name }}</option>
                </select>

              </div>
              <hr>
              <div>
                <p style="font-weight: 600;margin-top: 4vh" class="myTitle">专业方向</p>
                <select  v-model="subjectsTypeValue">
                  <option style="display:none" selected>{{ $route.query.main0 }}</option>
                  <option v-for="item of subjectsTypes" :key="item.id">{{ item.name }}</option>
                </select>
                <hr>
                <p style="font-weight: 600;margin-top: 4vh">二级专业方向</p>
                <select v-model="subjectsSubTypeValue">
                  <option  selected>{{ $route.query.main===''?'请选择':$route.query.main }}</option>
                  <option v-for="item of subjectsSubTypes.subject_type_sub" :key="item.id">{{ item.name }}</option>
                </select>
                <hr>
                </div>
              <div class="myBtn" @click="getval(levelValue,subjectsTypeValue,subjectsSubTypeValue)">查询课程</div>
            </div>
            <div class="filter-right">
              <div class="heightLine"></div>

              <div>
                <h4>专业筛选</h4>
                <div class="filter2">
                    <el-input v-model="moHu" placeholder="搜索你想了解的专业" class="search input-with-select">
                      <el-button @click="getvalAll(moHu)" slot="prepend" icon="el-icon-search"></el-button>
                    </el-input>
                </div>

                <div v-if="infos">
                  <div class="filterAll" >
                    <majorCard
                        class="card"
                        v-for="(item,index) of infos"
                        :key="item.id"
                        :item="item"
                    ></majorCard>
                  </div>
                  <div class="pagination">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        style="margin: 0 auto"
                        background
                        layout="prev, pager, next"
                        size="9"
                        :total="total">
                    </el-pagination>
                  </div>
                </div>
                <div v-else>
                  <h2 style="text-align: center;margin-top: 20vh">没有找到相关专业~~~</h2>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <showhotspot></showhotspot>
    </div>
</template>

<script>
    import data from '../data/project.json'
    import majorCard from "@/components/majorCard/majorCard";
    import showhotspot from "@/components/showhotspot";
    import {schoolService} from "@/services/school";
    import {constants} from "@/services/constants";
    import axios from "axios";
    export default {
        components: {
          majorCard,
          showhotspot
        },
        data() {
            return {
                data,
              hh:'',
              value: [200,200000],
              level: '',
              levelValue: '请选择',
              subjectsTypeValue:this.$route.query.main0,
              subjectsSubTypeValue:this.$route.query.main===''?'请选择':this.$route.query.main,
              infos:'',
              total:0,
              subjectsTypes:'',
              subjectsSubTypes:'',
              moHu:''
            }
        },
      created() {

        schoolService.getSubjectLevels().then((data) => {//获取专业等级
          this.level = data
        })

        this.getval(this.levelValue,this.$route.query.main0,this.$route.query.main)


      },

      methods:{
        handleCurrentChange(val) {//分页功能
          if (this.moHu === ''){
            let levelValue1
            let subjectsSubTypeValue1
            if (this.levelValue==='请选择'){
              levelValue1 = ''
            }else {
              levelValue1 = this.levelValue
            }
            if (this.subjectsSubTypeValue==='请选择'){
              subjectsSubTypeValue1 = ''
            }else {
              subjectsSubTypeValue1 = this.subjectsSubTypeValue
            }
            schoolService.getAllSubjects(`?page=${val}&subject_level__name=${levelValue1}&subject_type_sub__name=${subjectsSubTypeValue1}&subject_type_main__name=${this.subjectsTypeValue}&max_fee=${this.value[1]}&min_fee=${this.value[0]}`).then((data) => {
              this.infos = data.results
              this.total = data.count
            })
          }else {
            axios({
              method:'post',
              url:constants.SEARCH + `?page=${val}`,
              headers: {
                'content-type': 'application/json',
                'authorization': '92697bbf-bc6e-4b66-a446-89aed3f30b4f',
              },
              data:{
                type: '专业',
                content: this.moHu
              }
            }).then((res) => {
              this.infos = res.data.results
              this.total = res.data.count
              console.log(res)
            })
          }
          window.scrollTo(0, 0)
        },
        getval(levelValue,subjectsTypeValue,subjectsSubTypeValue){//点击查询课程
          // console.log(levelValue,subjectsTypeValue,subjectsSubTypeValue)
          this.moHu = ''
          if (levelValue==='请选择'){
            levelValue = ''
          }
          if(subjectsSubTypeValue==='请选择'){
            subjectsSubTypeValue=''
          }
          schoolService.getAllSubjects(`?subject_level__name=${levelValue}&subject_type_sub__name=${subjectsSubTypeValue}&subject_type_main__name=${subjectsTypeValue}&max_fee=${this.value[1]}&min_fee=${this.value[0]}`).then((data) => {
            if (data.count === 0){
              this.infos = false
            }else {
              this.infos = data.results
              this.total = data.count
              console.log(this.infos)
            }
          })

        },
        getSubjectsTypeValue(){
          this.subjectsSubTypes =  this.subjectsTypes.find((item) => {
            return this.subjectsTypeValue === item.name
          })
          if (this.subjectsSubTypes.subject_type_sub.length === 0){
            this.subjectsSubTypeValue = '暂无'

          }else {
            this.subjectsSubTypeValue = this.subjectsSubTypes.subject_type_sub[0].name
          }
        },
        getFee(val){
          console.log(val)
        },
        getvalAll(moHu){
          axios({
            method:'post',
            url:constants.SEARCH,
            headers: {
              'content-type': 'application/json',
              'authorization': '92697bbf-bc6e-4b66-a446-89aed3f30b4f',
            },
            data:{
              type: '专业',
              content: moHu
            }
          }).then((res) => {
            if (res.data.count === 0){
              this.infos = false
            }else {
              this.infos = res.data.results
              this.total = res.data.count
            }
            console.log(res)
          })
        }

      },
      watch:{
        $route:{
          handler(newVal){//监控路由变化来获取一二级专业选择内容

            schoolService.getSubjectTypes().then((data) => {//获取一级专业
              this.subjectsTypes = data
              this.getSubjectsTypeValue()
              this.subjectsTypeValue = this.$route.query.main0,
                  this.subjectsSubTypeValue = this.$route.query.main===''?'请选择':this.$route.query.main
            })
          },
          immediate: true
        },
        subjectsTypeValue:{
          handler(){//点击一级专业的时候二级专业跟着变化
            console.log(111)
            this.getSubjectsTypeValue()
          },
          // immediate: true,
          // deep: true
        }
      },
      metaInfo: {
            title: '新加坡留学网 - Project Details',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        },

    }
</script>
<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
hr{
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
}
.main{
  width: 80%;
  margin: 20px auto;
  h3{
  margin-top: 30px;
  }
}
.filter{
  display: flex;
  margin-top: 1.7vw;
  .filter-left{
    width: 20%;
    select{
      border: none;
      width: 15vw;
      padding-left: 1vw;
    }
    .block{
      input{
        width: 6vw;
        background-color: #EBEBEB;
        padding-left: 1vw;
        border: none;
        height: 3.5vw;
        opacity: .7;
      }
      input:nth-child(2){
        margin-left: 3vw;
      }
    }
    .eduction{
      div{
        margin-top: 1.5vh;
        opacity: .7;
        padding-left: 1vw;
      }
    }
  }
  .filter-right{
    width: 85%;
    display: flex;
    .pagination{
      margin: 3vw auto;
      text-align: center;
    }
    .heightLine{

      width: 2px;
      margin: 0 2.5vw;
      background-color: #E0E0E0;
    }
    .search{
      width: 39vw;
    }
    .filter2{
      margin-top: 2vh;
    }
    .filterAll{
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 3vh;
      .card{
        margin-top: 2vh;
      }
    }
  }
}
</style>

